.chatMessageToolCallItem {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  padding: 0 0 0 var(--inner-spacing-6);
  flex-direction: column;
  gap: var(--inner-spacing-3);
  align-items: start;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  transform: translateY(-25%);
}

.iconInProgress {
  color: var(--color-bg-accent);
}

.iconPendingApproval {
  color: var(--color-bg-warning);
}

.iconSuccess {
  color: var(--color-bg-positive);
}

.iconError {
  color: var(--color-bg-negative);
}

.arguments {
  width: 100%;
  white-space: break-spaces;
  padding: var(--inner-spacing-2);
  border-radius: var(--border-radius-elevation-3);
  background: var(--color-bg-neutral-subtle);
}

.argumentsJson {
  font-size: 12px;
  padding: var(--inner-spacing-2);
}
